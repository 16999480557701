import * as React from "react"
import moment from "moment"
import ImageService from "../../utilities/images-service"

import "./styles.scss"

const TopNews = ({ blok }) => {
    return (
    <a href={blok[0].link.url} target="_blank" className="top-news">
        <img src={ImageService(blok[0].image.filename, true, { path: '118x118/smart'})} width={59} height={59} alt={blok[0].title}/>
        <div className="title">
            <h3>{blok[0].title}</h3>
            <time dateTime={moment(blok[0].publicationDate).format("YYYY-MM-DD HH:mm")}>{moment(blok[0].publicationDate).format("DD.MM.YYYY")}</time>
            <span>Mehr erfahren</span>
        </div>
    </a>)
}

export default TopNews