import * as React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import TopNews from "../topnews"

import { useDispatch } from "react-redux"

import "./styles.scss"

const Header = ({ news }) => {
  const [menuClasses, setMenuClasses] = React.useState("")

  const dispatch = useDispatch()

  const openIntroduction = (event) => {
    event.preventDefault()
    setMenuClasses("open")
    setTimeout(()=> { setMenuClasses("")}, 300)
    dispatch({
      type: "SHOW_MODAL"
    })
    navigate('/')
  }

  return (
    <header>
      <div className="item item-menu">
        <div className="navigation">
          <div className="menu-icon" onClick={() => {
            setMenuClasses("open")
            setTimeout(()=> { setMenuClasses("open animate")}, 100)
          }} />
          <div className={"flyout " + menuClasses}>
            <div className="menu">
              <div className="close-icon" onClick={() => {
                setMenuClasses("open")
                setTimeout(()=> { setMenuClasses("")}, 300)
              }}></div>
              <h5>Menü</h5>
              <ul>
                <li>
                 <a href="#" onClick={openIntroduction}>Vorwort</a>
                </li>
                <li>
                  <Link to="/kontakt">Kontakt</Link>
                </li>
                <li>
                  <a href="https://www.bezreg-muenster.de/de/datenschutz/index.html" target="_blank" rel="noreferrer">Datenschutz</a>
                </li>
                <li>
                  <a href="https://www.bezreg-muenster.de/de/impressum/index.html" target="_blank" rel="noreferrer">Impressum</a>
                </li>
              </ul>
              <h5>Eine Initiative von</h5>
              <div className="partners">
                <a href="https://www.bezreg-muenster.de/de/index.html" target="_blank" rel="noreferrer">
                  <StaticImage 
                    src="../../images/logo.png"
                    width={170}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Bezirksregierung Münster"
                    loading="eager"
                    placeholder="none"
                  />
                </a>
                <a href="https://www.innenstadt-nrw.de/aktuell" target="_blank" rel="noreferrer">
                  <StaticImage 
                    src="../../images/netzwerk-innenstadt-logo.png"
                    width={85}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Netzwerk Innenstadt NRW"
                    loading="eager"
                    placeholder="none"
                  />
                </a>
                <a href="https://www.ils-forschung.de" target="_blank" rel="noreferrer">
                <StaticImage 
                  src="../../images/ils-logo.png"
                  width={128}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="ILS"
                  loading="eager"
                  placeholder="none"
                />
                </a>
                <a href="https://www.ihk-nordwestfalen.de" target="_blank" rel="noreferrer">
                <StaticImage 
                  src="../../images/ihk-logo.png"
                  width={136}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="IHK Nord Westfalen"
                  loading="eager"
                  placeholder="none"
                />
                </a>
              </div>
            </div>
            <div className="background" onClick={() => {
                setMenuClasses("open")
                setTimeout(()=> { setMenuClasses("")}, 300)
            }}></div>
          </div>
        </div>
      </div>
      <div className="item item-news">
      </div>
      <div className="item item-logo" style={{ textAlign: "right" }}>
        <a href="https://www.bezreg-muenster.de/de/index.html" target="_blank" rel="noreferrer">
          <StaticImage 
            src="../../images/logo.png"
            width={180}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Bezirksregierung Münster"
            loading="eager"
            placeholder="none"
          />
        </a>
      </div>
    </header>
  )
}

Header.propTypes = {
  news: PropTypes.object,
}

Header.defaultProps = {
  news: ``,
}

export default Header
